import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Column, Container, Row } from '../Grid';
import Icon from '../Icon';
import Image from '../Image';
import Action from './Action';

import './styles.scss';

const Resource = ({
  tophat,
  tophatColor,
  img,
  icon,
  subject,
  title,
  subtitle,
  duration,
  description,
  actions,
  horizontal,
  className,
  alignedButtons,
  withBorder,
}) => {
  const getClassNames = () =>
    classNames(
      'resource',
      horizontal && 'resource--horizontal',
      withBorder && 'resource--bordered',
      className
    );

  const renderImage = () => {
    let image = (
      <>
        <Image filename={img} className="resource__image" />
        {icon && <Image filename={icon} className="resource__icon" />}
      </>
    );

    if (actions && actions[0].linkFromImg) {
      const { metadata, label, ...actionProps } = actions[0];
      image = (
        <Action
          {...actionProps}
          label={image}
          noIcon={!actions[0].module}
          asLink
        />
      );
    }

    return (
      <>
        {tophat && (
          <div
            className="resource__tophat"
            style={{ backgroundColor: `${tophatColor}` }}
          >
            {tophat}
          </div>
        )}
        <div className="resource__image-container">{image}</div>
      </>
    );
  };

  const renderContent = () => (
    <div className="resource__content">
      {subject && <div className="resource__subject">{subject}</div>}
      <h2 className="resource__title">{title}</h2>
      {subtitle && <div className="resource__subtitle">{subtitle}</div>}
      {duration && (
        <div className="resource__duration">
          <Icon name="clock" marginRight /> {duration}
        </div>
      )}
      {description && (
        <div
          className="resource__description"
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        ></div>
      )}
      {actions && (
        <div
          className={classNames(
            'resource__actions',
            alignedButtons && 'resource__actions--aligned'
          )}
        >
          {actions.map((action, i) => (
            <Action key={i} {...action} />
          ))}
        </div>
      )}
    </div>
  );

  if (horizontal) {
    return (
      <Container>
        <div className={getClassNames()}>
          <Row>
            <Column size={5}>{renderImage()}</Column>
            <Column size={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    );
  }

  return (
    <div className={getClassNames()}>
      {renderImage()}
      {renderContent()}
    </div>
  );
};

Resource.defaultProps = {
  tophat: null,
  subject: null,
  subtitle: null,
  duration: null,
  description: null,
  actions: null,
  horizontal: false,
  className: null,
  alignedButtons: true,
  withBorder: true,
};

Resource.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The icon's file name */
  icon: PropTypes.string,
  /** Text for the tophat */
  tophat: PropTypes.string,
  /** The subject */
  subject: PropTypes.string,
  /** The title */
  title: PropTypes.string.isRequired,
  /** The subtitle */
  subtitle: PropTypes.string,
  /** The duration */
  duration: PropTypes.string,
  /** The description */
  description: PropTypes.node,
  /** The actions */
  actions: PropTypes.arrayOf(PropTypes.object),
  /** Horizontal orientation */
  horizontal: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
  /** Align buttons in multiple cards horizontally */
  alignedButtons: PropTypes.bool,
  /** Surround the card with a border */
  withBorder: PropTypes.bool,
};

export default Resource;
