import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../Modal';
import Image from '../Image';
import Video from '../Video';
import { Column } from '../Grid';
import './styles.scss';

/** A simple `section` element that provides some vertical spacing */

const HorizontalAssetCard = ({
  className,
  children,
  imageSource,
  video,
  VideoGuid,
  VideoTitle,
  VideoDescription,
  ...other
}) => {
  const renderModal = () => {
    if (video) {
      return (
        <Modal
          video
          trigger={
            <div className="horizontal-asset-card__modal-trigger">
              <Image
                filename={imageSource}
                className="horizontal-asset-card__modal-image"
              />
              <Image
                filename="/btn-play-default.png"
                className="horizontal-asset-card__modal-play-button"
              />
            </div>
          }
        >
          <h2>Explainer Video</h2>
          <Video
            className="horizontal-asset-card__video"
            guidSrc={VideoGuid}
            autoPlay={true}
          />
        </Modal>
      );
    } else {
      return (
        <div className="horizontal-asset-card__modal-trigger">
          <Image
            filename={imageSource}
            className="horizontal-asset-card__modal-image"
          />
        </div>
      );
    }
  };

  return (
    <div className="horizontal-asset-card">
      {renderModal()}
      <div className="horizontal-asset-card__content">
        <p className="mb-0 horizontal-asset-card__type">Explainer Video</p>
        <p className="mb-0 horizontal-asset-card__title">{VideoTitle}</p>
        <p>{VideoDescription}</p>
      </div>
    </div>
  );
};

HorizontalAssetCard.defaultProps = {
  className: null,
  comingSoon: false,
};

HorizontalAssetCard.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  imageSource: PropTypes.string,
  VideoGuid: PropTypes.string.isRequired,
  VideoTitle: PropTypes.string,
  VideoDescription: PropTypes.string,
  comingSoon: PropTypes.bool,
};

export default HorizontalAssetCard;
