import { graphql } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Resource from '../components/Resource';
import Section from '../components/Section';
import Video from '../components/Video';
import Modal from '../components/Modal';
import { getKeyedResources } from '../utils/resource-helper';
import HorizontalAssetCard from '../components/HorizontalAssetRow';

const ClassroomResourcesPage = ({ data }) => {
  const {
    fluencyAutomaticity,
    fluencyProsody,
    fluencyReadingSpeed,
    phonemicAwarenessRythm,
    phonemicAwarenessSoundsSequence,
    phonemicAwarenessSoundManipulation,
    phonicsAlphabeticPrinciple,
    phonicsDecoding,
    phonicsWordStudy,
    readingComprehensionBackgroundKnowledge,
    readingComprehensionReadingStrategies,
    readingComprehensionTextStructures,
    vocabularyTier1,
    vocabularyTier2,
    vocabularyTier3,
  } = getKeyedResources(data);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Layout title="Learning Resources">
      <Hero
        mediaSource="classroomresources-hero.png"
        className="classroom-hero mb-0"
      >
        <Container className="classroom-hero__content">
          <Image
            filename="classroom-hero-cosmos-leftside.png"
            className="classroom-hero__content__cosmos-leftside"
          />
          {/* <Image
            filename="classroom-hero-cloud.png"
            className="classroom-hero__content__cloud"
            objectFit="fill"
          /> */}
          <Image
            filename="classroom-hero-cosmos-rightside.png"
            className="classroom-hero__content__cosmos-rightside"
          />
          <Image
            filename="classroom-hero-astronaut.png"
            className="classroom-hero__content__astronaut"
          />

          <h1 className="title classroom-hero__content__title">
            Learning <br /> <br />
            Resources
          </h1>
        </Container>
      </Hero>
      <Section>
        <Container>
          <Row>
            <Column size={10}>
              <h1>Discover New Ways to Teach Literacy Essentials</h1>
              <p className="mt-2">
                Create everyday connections to the five foundational keys of
                literacy with on-the-go microlearnings and flexible
                instructional supports. Whether you work in a classroom or after
                school program, these hands-on resources provide an explicit,
                systematic approach to instruction and opportunities to practice
                applying new skills.
              </p>
            </Column>
            <Column size={2} className="align-self-center">
              <Image
                filename="classroomresources-intro-books.png"
                className="classroom-intro__container__right-image mx-auto"
              />
            </Column>
          </Row>
          <Container className="mt-3 mb-2">
            <Row>
              <Column size={5}>
                <div className="classroom-intro__how-to-wrapper">
                  <Image
                    filename="03-classroom-numbers-1.png"
                    className="classroom-intro__how-to-numbers"
                  />
                  <div>
                    <h3>Build a Foundation</h3>
                    <p>
                      Watch the video at the beginning of each section to learn
                      about one of the five basic skills of literacy.
                    </p>
                  </div>
                </div>

                <div className="classroom-intro__how-to-wrapper">
                  <Image
                    filename="03-classroom-numbers-2.png"
                    className="classroom-intro__how-to-numbers"
                  />
                  <div>
                    <h3>Expand Your Knowledge</h3>
                    <p>
                      Click on "Launch Microlearning Module" and complete the
                      6-8 minute, self-paces microlearn. Each one provides you
                      with new strategies and activities ready to use with
                      students as soon as today.
                    </p>
                  </div>
                </div>
              </Column>
              <Column size={5} offset={1}>
                <div className="classroom-intro__how-to-wrapper">
                  <Image
                    filename="03-classroom-numbers-3.png"
                    className="classroom-intro__how-to-numbers"
                  />
                  <div>
                    <h3>Print Your Certificate</h3>
                    <p>
                      Upon completion of each microlearn you'll be issued a
                      certificate as evidence of your learning. There are 15
                      certificates in all!
                    </p>
                  </div>
                </div>
                <div className="classroom-intro__how-to-wrapper">
                  <Image
                    filename="03-classroom-numbers-4.png"
                    className="classroom-intro__how-to-numbers"
                  />
                  <div>
                    <h3>Make a Difference</h3>
                    <p>
                      Whether you're a classroom teacher, parent, or work with
                      students in an afterschool program, these bite sized
                      activities and quick professional learnings are sure to
                      sharpen your literacy skills for teaching students to
                      become confident readers.
                    </p>
                  </div>
                </div>
              </Column>
            </Row>
          </Container>
          <Quicklinks
            links={[
              {
                target: '#phonemic-awareness',
                label: 'Phonological Awareness',
              },
              {
                target: '#phonics',
                label: 'Phonics',
              },
              {
                target: '#fluency',
                label: 'Fluency',
              },
              {
                target: '#vocabulary',
                label: 'Vocabulary',
              },
              {
                target: '#reading-comprehension',
                label: 'Reading Comprehension',
              },
            ]}
          />
        </Container>
      </Section>

      {/* Resources */}
      <Section id="phonemic-awareness" className="classroom-phonemic">
        <Container>
          <Row>
            <Column size={5}>
              <div style={{ marginRight: '1em' }}>
                <h1>Phonological Awareness</h1>
                <p>
                  Explore the power that students have to engage in meaningful
                  interactions by learning to identify and manipulate the
                  individual sound units within spoken words.
                </p>
              </div>
            </Column>
            <Column size={7}>
              <HorizontalAssetCard
                video
                imageSource="classroom-explainerVideo-thumb-phonemicAwareness.jpg"
                VideoGuid="26bd40e2-1ed7-4fd4-a75a-7267ba71b92d"
                VideoTitle="What is Phonological Awareness?"
                VideoDescription="Explore how sounds are used to form the building blocks of words by learning about phonological awareness."
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={phonemicAwarenessRythm.tophat}
                tophatColor={phonemicAwarenessRythm.tophatColor}
                title={phonemicAwarenessRythm.title}
                duration={phonemicAwarenessRythm.duration}
                className={phonemicAwarenessRythm.className}
                subtitle={phonemicAwarenessRythm.subtitle}
                description={phonemicAwarenessRythm.description}
                img={phonemicAwarenessRythm.img}
                actions={phonemicAwarenessRythm.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={phonemicAwarenessSoundsSequence.tophat}
                tophatColor={phonemicAwarenessSoundsSequence.tophatColor}
                title={phonemicAwarenessSoundsSequence.title}
                duration={phonemicAwarenessSoundsSequence.duration}
                className={phonemicAwarenessSoundsSequence.className}
                subtitle={phonemicAwarenessSoundsSequence.subtitle}
                description={phonemicAwarenessSoundsSequence.description}
                img={phonemicAwarenessSoundsSequence.img}
                actions={phonemicAwarenessSoundsSequence.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={phonemicAwarenessSoundManipulation.tophat}
                tophatColor={phonemicAwarenessSoundManipulation.tophatColor}
                title={phonemicAwarenessSoundManipulation.title}
                duration={phonemicAwarenessSoundManipulation.duration}
                className={phonemicAwarenessSoundManipulation.className}
                subtitle={phonemicAwarenessSoundManipulation.subtitle}
                description={phonemicAwarenessSoundManipulation.description}
                img={phonemicAwarenessSoundManipulation.img}
                actions={phonemicAwarenessSoundManipulation.actions}
              />
            </Column>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
        </Container>
      </Section>

      <Section id="phonics" className="classroom-phonics">
        <Container>
          <Row>
            <Column size={5}>
              <div style={{ marginRight: '1em' }}>
                <h1>Phonics</h1>
                <p>
                  Strengthen students’ understanding of the relationship between
                  the sound of a word or letter and its literal meaning with
                  these phonemics-focused resources.
                </p>
              </div>
            </Column>
            <Column size={7}>
              <HorizontalAssetCard
                video
                imageSource="classroom-explainerVideo-thumb-phonics.jpg"
                VideoGuid="f729d6db-eda0-4954-bcd9-e55d0cecb585"
                VideoTitle="What is Phonics?"
                VideoDescription="Discover methods to help learners build a strong foundation in reading by understanding the relationship between sounds and letters."
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={phonicsAlphabeticPrinciple.tophat}
                tophatColor={phonicsAlphabeticPrinciple.tophatColor}
                title={phonicsAlphabeticPrinciple.title}
                duration={phonicsAlphabeticPrinciple.duration}
                className={phonicsAlphabeticPrinciple.className}
                subtitle={phonicsAlphabeticPrinciple.subtitle}
                description={phonicsAlphabeticPrinciple.description}
                img={phonicsAlphabeticPrinciple.img}
                actions={phonicsAlphabeticPrinciple.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={phonicsDecoding.tophat}
                tophatColor={phonicsDecoding.tophatColor}
                title={phonicsDecoding.title}
                duration={phonicsDecoding.duration}
                className={phonicsDecoding.className}
                subtitle={phonicsDecoding.subtitle}
                description={phonicsDecoding.description}
                img={phonicsDecoding.img}
                actions={phonicsDecoding.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={phonicsWordStudy.tophat}
                tophatColor={phonicsWordStudy.tophatColor}
                title={phonicsWordStudy.title}
                duration={phonicsWordStudy.duration}
                className={phonicsWordStudy.className}
                subtitle={phonicsWordStudy.subtitle}
                description={phonicsWordStudy.description}
                img={phonicsWordStudy.img}
                actions={phonicsWordStudy.actions}
              />
            </Column>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
        </Container>
      </Section>

      <Section id="fluency" className="classroom-fluency">
        <Container>
          <Row>
            <Column size={5}>
              <div style={{ marginRight: '1em' }}>
                <h1>Fluency</h1>
                <p>
                  Enhance the comfort students feel using language by honing
                  their ability to read quickly, accurately, and with proper
                  pronunciation.
                </p>
              </div>
            </Column>
            <Column size={7}>
              <HorizontalAssetCard
                video
                imageSource="classroom-explainerVideo-thumb-fluency.jpg"
                VideoGuid="7e2dcca8-44f1-4c75-b00a-c2250aad8c5d"
                VideoTitle="What is Fluency? "
                VideoDescription="Find out what essential skills are needed to improve reading speed, accuracy, and expressive delivery for a more engaging and proficient reading experience."
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={fluencyAutomaticity.tophat}
                tophatColor={fluencyAutomaticity.tophatColor}
                title={fluencyAutomaticity.title}
                duration={fluencyAutomaticity.duration}
                className={fluencyAutomaticity.className}
                subtitle={fluencyAutomaticity.subtitle}
                description={fluencyAutomaticity.description}
                img={fluencyAutomaticity.img}
                actions={fluencyAutomaticity.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={fluencyReadingSpeed.tophat}
                tophatColor={fluencyReadingSpeed.tophatColor}
                title={fluencyReadingSpeed.title}
                duration={fluencyReadingSpeed.duration}
                className={fluencyReadingSpeed.className}
                subtitle={fluencyReadingSpeed.subtitle}
                description={fluencyReadingSpeed.description}
                img={fluencyReadingSpeed.img}
                actions={fluencyReadingSpeed.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={fluencyProsody.tophat}
                tophatColor={fluencyProsody.tophatColor}
                title={fluencyProsody.title}
                duration={fluencyProsody.duration}
                className={fluencyProsody.className}
                subtitle={fluencyProsody.subtitle}
                description={fluencyProsody.description}
                img={fluencyProsody.img}
                actions={fluencyProsody.actions}
              />
            </Column>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
        </Container>
      </Section>

      <Section id="vocabulary" className="classroom-vocabulary">
        <Container>
          <Row>
            <Column size={5}>
              <div style={{ marginRight: '1em' }}>
                <h1>Vocabulary</h1>
                <p>
                  Teach students how to develop a versatile vocabulary with
                  resources that bring words to life through visual and
                  interactive learning.
                </p>
              </div>
            </Column>
            <Column size={7}>
              <HorizontalAssetCard
                video
                imageSource="classroom-explainerVideo-thumb-vocabulary.jpg"
                VideoGuid="8d83d6ac-69fe-4c05-829c-6f4afe714e62"
                VideoTitle="What is Vocabulary?"
                VideoDescription="Explore the crucial role of vocabulary in literacy instruction, highlighting how it shapes our ability to comprehend and use language effectively."
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={vocabularyTier1.tophat}
                tophatColor={vocabularyTier1.tophatColor}
                title={vocabularyTier1.title}
                duration={vocabularyTier1.duration}
                className={vocabularyTier1.className}
                subtitle={vocabularyTier1.subtitle}
                description={vocabularyTier1.description}
                img={vocabularyTier1.img}
                actions={vocabularyTier1.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={vocabularyTier2.tophat}
                tophatColor={vocabularyTier2.tophatColor}
                title={vocabularyTier2.title}
                duration={vocabularyTier2.duration}
                className={vocabularyTier2.className}
                subtitle={vocabularyTier2.subtitle}
                description={vocabularyTier2.description}
                img={vocabularyTier2.img}
                actions={vocabularyTier2.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={vocabularyTier3.tophat}
                tophatColor={vocabularyTier3.tophatColor}
                title={vocabularyTier3.title}
                duration={vocabularyTier3.duration}
                className={vocabularyTier3.className}
                subtitle={vocabularyTier3.subtitle}
                description={vocabularyTier3.description}
                img={vocabularyTier3.img}
                actions={vocabularyTier3.actions}
              />
            </Column>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
        </Container>
      </Section>

      <Section
        id="reading-comprehension"
        className="classroom-reading-comprehension"
      >
        <Container className="mb-3">
          <Row>
            <Column size={5}>
              <div style={{ marginRight: '1em' }}>
                <h1>Reading Comprehension</h1>
                <p>
                  Reinforce the foundation of knowledge that students use to
                  interpret language with resources that break down the basics
                  of reading comprehension.
                </p>
              </div>
            </Column>
            <Column size={7}>
              <HorizontalAssetCard
                video
                imageSource="classroom-explainerVideo-thumb-readingComprehension.jpg"
                VideoGuid="79191843-10c6-4023-9b52-087d617c9ff7"
                VideoTitle="What is Reading Comprehension? "
                VideoDescription="Take a closer look at the way our mind extracts meaning from text and retains the content more efficiently."
              />
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={readingComprehensionBackgroundKnowledge.tophat}
                tophatColor={
                  readingComprehensionBackgroundKnowledge.tophatColor
                }
                title={readingComprehensionBackgroundKnowledge.title}
                duration={readingComprehensionBackgroundKnowledge.duration}
                className={readingComprehensionBackgroundKnowledge.className}
                subtitle={readingComprehensionBackgroundKnowledge.subtitle}
                description={
                  readingComprehensionBackgroundKnowledge.description
                }
                img={readingComprehensionBackgroundKnowledge.img}
                actions={readingComprehensionBackgroundKnowledge.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={readingComprehensionReadingStrategies.tophat}
                tophatColor={readingComprehensionReadingStrategies.tophatColor}
                title={readingComprehensionReadingStrategies.title}
                duration={readingComprehensionReadingStrategies.duration}
                className={readingComprehensionReadingStrategies.className}
                subtitle={readingComprehensionReadingStrategies.subtitle}
                description={readingComprehensionReadingStrategies.description}
                img={readingComprehensionReadingStrategies.img}
                actions={readingComprehensionReadingStrategies.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={readingComprehensionTextStructures.tophat}
                tophatColor={readingComprehensionTextStructures.tophatColor}
                title={readingComprehensionTextStructures.title}
                duration={readingComprehensionTextStructures.duration}
                className={readingComprehensionTextStructures.className}
                subtitle={readingComprehensionTextStructures.subtitle}
                description={readingComprehensionTextStructures.description}
                img={readingComprehensionTextStructures.img}
                actions={readingComprehensionTextStructures.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>

      <Section className="classroom-quote">
        <Container className="classroom-quote__container">
          <Row>
            <Column size={2} className="classroom-quote__container__astronaut">
              <Image filename="classroomresources-quote-astronaut.png" />
            </Column>
            <Column size={8} className="classroom-quote__container__text">
              <h1>
                Some 34% of students are below basic reading level in the fourth
                grade.
              </h1>
              <p className="large mt-2">
                —U.S. National Center for Education Statistics
              </p>
            </Column>
            <Column size={2} className="classroom-quote__container__rocket">
              <Image filename="classroomresources-quote-rocket.png" />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query StudentResources {
    allResourcesJson(filter: { pages: { in: "classroom-resources" } }) {
      edges {
        node {
          slug
          tophat
          tophatColor
          img
          icon
          subject
          title
          subtitle
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            link
            download
            noIcon
            asLink
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default ClassroomResourcesPage;
